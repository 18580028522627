import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { auth } from 'firebase';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
  @Input('ngModel')
  feedback: String;
  itemsRef: AngularFireList<any>;
  tokens: AngularFireObject<any>;
  items: Observable<any[]>;
  id: String;
  userId: String;
  isLoggedIn: Boolean;
  error: any;
  isSending: Boolean = false;
  isSent: Boolean = false;
  message: any= '';
  expiry: Date;
  isExpired: Boolean = false;

  constructor(
    private db: AngularFireDatabase,
    private auth: AngularFireAuth,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService
  ) {
     this.itemsRef = db.list('feedbacks');
    //  this.translateService.setDefaultLang('ar');
    //  this.translateService.use('ar');
    this.translateService.setDefaultLang('ar');
    this.translateService.use('ar');
  }

  ngOnInit(): void {
    if (this.isLoggedIn) {
      this.readQueryParam();
    } else {
      console.log('user not logged in going to sign in');
      this.auth.signInAnonymously().then((subject) => {
        this.isLoggedIn = true;
        this.readQueryParam();
      });
    }
  }

  private readQueryParam() {
    this.route.queryParams.subscribe((params) => {
      this.id = params['id'];
      // console.log('ID from query params :' + this.id);
      this.db.database
        .ref('/tokens/' + this.id)
        .once('value')
        .then((snapshot) => {
          this.userId = (snapshot.val() && snapshot.val().userid) || '';
          const expiryTime = Number.parseInt(
            (snapshot.val() && snapshot.val().expiry) || '0'
          );
          this.expiry = new Date();
          this.expiry.setTime(expiryTime);
          const timeDiff = this.expiry.getTime() - new Date().getTime();
          this.isExpired = timeDiff && timeDiff < 0;
          console.log('Expiry : ' + this.isExpired);
          this.checkExpiry();
        })
        .catch((reason) => {
          console.log('error : ' + reason);
        });
    });
  }

  onSubmit(event: Event) {
    console.log('submit clicked' + event);
    console.log(this.feedback);
    try {
      this.checkExpiry();
    } catch (e) {
      return;
    }
    this.writeNewFeedback(this.userId, this.feedback)
      .then((value) => {
        this.isSending = false;
        this.isSent = true;
        this.message = 'Feedback sent successfully';
        this.feedback = null;
        // console.log('Message : ' + this.message);
      })
      .catch((reason) => {
        this.error = 'Error sending feedback please try again !';
        console.log('error : ' + reason);
      });
  }
  private checkExpiry() {
    if (this.isExpired) {
      this.error = 'The link is not valid ! Please request for a new link ';
      throw new Error('The link is not valid ! Please request for a new link ');
    }
  }

  async writeNewToken(uid: String, expiry: Number) {
    var postData = {
      userid: uid,
      expiry: expiry,
    };
    var newToken = this.db.database.ref().child('tokens').push().key;
    var updates = {};
    updates['/tokens/' + newToken] = postData;
    return this.db.database
      .ref()
      .update(updates)
      .catch((reason) => {
        console.log('error : ' + reason);
      });
  }
  async writeNewFeedback(uid: String, feedbackAtt: String) {
    this.isSending = true;
    this.error = null;
    this.isSent = false;
    var date = new Date().getTime().toString();
    console.log('date ----' + date);
    var postData = {
      feedback: feedbackAtt,
      date: date,
    };
    var updates = {};
    updates['/feedbacks/' + uid] = postData;
    return this.db.database.ref('/feedbacks/' + uid).push(postData);
  }
  goHome(event: Event) {
    this.router.navigate(['/home']);
  }
}
