import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  @Input('ngModel')
  name:String;
  email:String;
  phone:String;
  messageVal: String;
  itemsRef: AngularFireList<any>;
  tokens: AngularFireObject<any>;
  items: Observable<any[]>;
  id: String;
  userId: String;
  isLoggedIn: Boolean;
  error: any;
  isSending: Boolean = false;
  isSent: Boolean = false;
  message: any ;
  expiry: Date;
  isExpired: Boolean = false;
  
  constructor(
    private db: AngularFireDatabase,
    private auth: AngularFireAuth,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService
  ) {
     this.itemsRef = db.list('contact');
    //  this.translateService.setDefaultLang('ar');
    //  this.translateService.use('ar');
    this.translateService.setDefaultLang('ar');
    this.translateService.use('ar');
  }

  ngOnInit(): void {
  }
  private readQueryParam() {
    this.route.queryParams.subscribe((params) => {
      this.id = params['id'];
      // console.log('ID from query params :' + this.id);
      this.db.database
        .ref('/tokens/' + this.id)
        .once('value')
        .then((snapshot) => {
          this.userId = (snapshot.val() && snapshot.val().userid) || '';
          const expiryTime = Number.parseInt(
            (snapshot.val() && snapshot.val().expiry) || '0'
          );
          this.expiry = new Date();
          this.expiry.setTime(expiryTime);
          const timeDiff = this.expiry.getTime() - new Date().getTime();
          this.isExpired = timeDiff && timeDiff < 0;
          console.log('Expiry : ' + this.isExpired);
          this.checkExpiry();
        })
        .catch((reason) => {
          console.log('error : ' + reason);
        });
    });
  }
  private checkExpiry() {
    if (this.isExpired) {
      this.error = 'The link is not valid ! Please request for a new link ';
      throw new Error('The link is not valid ! Please request for a new link ');
    }
  }
  goHome(event: Event) {
    this.router.navigate(['/home']);
  }
  validateEmail(email) {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(String(email).toLowerCase());
   }
  onSubmit(event: Event) {
    if(this.email!==null && this.name!=null){
      if(this.validateEmail(this.email)){
        
      this.writeNewMessage(this.userId, this.messageVal,this.email,this.phone,this.name)
      .then((value) => {
        this.isSending = false;
        this.isSent = true;
        this.message = 'Message sent successfully';
        this.messageVal = null;
        this.email = null;
        this.phone = null;
        this.name = null;
      })
      .catch((reason) => {
        this.error = 'Error sending message please try again !';
        console.log('error : ' + reason);
      });
      }
      else{
        this.error = 'Error : Enter correct email!';
      }
    }
    else{
      this.error = 'Error : Add required details !';
    }
    
  }
  async writeNewMessage(uid: String, message: String, email: String, phone: String, name: String) {
    this.isSending = true;
    this.error = null;
    this.isSent = false;
    var date = new Date().getTime().toString();
    console.log('date ----' + date);
    var postData = {
      date: date,
      message: message,
      name: name,
      contact: phone,
      email:email,
    };
    var updates = {};
    updates['/contact/' + email] = postData;
    return this.db.database.ref('/contact/').push(postData);
  }

}
