<div class="content" role="main">
  <!--
    <img class="logo" src="../../assets/images/updated/MazeedLogoSqV1.png" alt="" width="50" height="50">
  -->
  <div class="row-content-top">
    <img src="../../assets/images/updated/screen1.png" alt="" class="app-image">
    <div translate class="white-text">
       <!--Your one stop solution for all your goal setting and tracking needs
     You don’t need to have<br>separate apps for<br> creating goals<br> todo list and journals<br><br>-->
      <br><br><span translate class="title-bold">
        MAZEED
      </span>
      <br>
      <span translate class="sub-content">Your one stop solution for all your goal setting and tracking needs</span><br><br>

      <div>
        <span class="store-section">
          <a class="store-logo" href="https://play.google.com/store/apps/details?id=app.mazeed">
            <img src="../../assets/images/updated/android.png" alt="" width="120" height="40">
          </a>
        </span>
        <span class="store-section">

          <a class="store-logo" href="https://apps.apple.com/in/app/mazeed/id1626988429" >
            <img src="../../assets/images/updated/ios.png" alt="" width="120" height="40">
          </a>
        </span>
      </div>



    </div>
    <!-- <P translate class="content-text">
      Mazeed is your one stop solution
    </P> -->

  </div>



  <!-- Create your goals and associated habits -->
  <!-- <div class="row-content">
    <img src="../../assets/images/updated/screen2.png" alt ="" class="app-image">
    <P translate class="content-title">
      Create your goals and associated habits
    </P>
  </div> -->

  <!-- Track your goals and associated habits -->
  <div class="row-content todo">
    <div>
      <P translate class="content-title">
        Track your goals and associated habits
      </P>
    </div>
    <img src="../../assets/images/updated/goals.png" alt="" class="app-image">
  </div>

  <!-- Track your today’s activities -->
  <div class="row-content">

    <img src="../../assets/images/updated/Activities.png" alt="" class="app-image">
    <div>
      <P translate class="content-title">
        Track your habits
      </P>
    </div>
  </div>

  <!-- Mark updated activities on streak -->
  <div class="row-content todo">
    <div>
      <P translate class="content-title">
        Mark updated activities on streak
      </P>
    </div>
    <img src="../../assets/images/updated/Streak.png" alt="" class="app-image">
  </div>

  <!-- Track your anonymous feedback -->
  <!-- <div class="row-content todo">
    <div>
      <P translate class="content-title">
        Track your anonymous feedback
    </div>
    <img src="../../assets/images/updated/screen2.png" alt="" class="app-image">
  </div>
   -->
   <!--<div class="link-row">
    <a href="/"><span translate class="white-text-copyright">Home</span></a>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
     <a href="/contact"><span translate class="white-text-copyright">Contact</span></a>
     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
     <a href="/privacy-policy"><span translate class="white-text-copyright">Privacy-Policy</span></a>
     
   </div>
   <br>
   &nbsp;
   <br>
   <div class="white-text-copyright">Copyright <i class="fa fa-copyright" aria-hidden="true"> </i>
    2022 Mazeed. All Rights Reserved</div>-->
    <footer class="footer">
      <div class="container">
        <div class="row pt-5">
          <div class="col-sm-12 col-12 pt-3 footer-list">
            <hr class="bg-light">
            <ul class="d-flex justify-content-center align-items-center">
              <li><a translate href="/" class="text-white">Home</a></li>
              <li class="d-none d-sm-block"><a href="" class="text-white">|</a></li>
              <li><a translate href="/contact" class="text-white">Contact</a></li>
              <li class="d-none d-sm-block"><a href="" class="text-white">|</a></li>
              <li><a translate href="/privacy-policy" class="text-white">Privacy-Policy</a></li>
              </ul>
          </div>
      </div></div>
    <div class="footer-bottom">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 pt-4">
                <p translate class="text-white text-center pt-1">Copyright © 2022 Mazeed. All Rights Reserved.</p>
            </div>
        </div></div> 
    </div>
    </footer>
</div>
