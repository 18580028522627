<div class="feedback-container">
    <!--<P translate *ngIf="error != null" class="error-text">{{error | translate}}</P>-->
    <label translate class="text-label margin-bot">Privacy Policy</label>
    <br>
    <p class="small-text-label">Protecting the security and privacy of the user’s personal data is important to the website/application. This Privacy Policy is a clear statement regarding the manner in which your data is collected and used by the website/application. You are advised to read the Privacy Policy carefully. </p>
    <br>
    <div class="align-left text-label">Personal Data</div>
    <br>
    <p class="small-text-label">We will not collect any personal data about you (e.g. your name, address, telephone number or e-mail address), unless you voluntarily choose to provide us with it with your consent (e.g. by registration, survey, contact forms)</p>
    <br>
    <div class="align-left text-label">Purpose of Use</div>
    <br>
    <p class="small-text-label">The personal data provided by the user is generally used to respond to their inquiry, to provide access to specific information or offers, or to strengthen our mutual relationship:</p>
    <p class="small-text-label-less">
               1) We may store and process personal data and share it with our affiliate to improve the user experience. We/our affiliates may use personal data to contact you to share some relevant information regarding our initiatives, updates, our other websites/applications, our events, newsletters etc. If you would not wish to receive any of this additional information as detailed in this paragraph (or any part of it) please click the “unsubscribe” link in any email that we send to you. Within 7 working days (days which are neither (i) a Saturday nor Sunday, nor (ii) a public holiday anywhere in India) of receipt of your instruction we will cease to send you information as requested. If your instruction is unclear, you may be contacted for clarification.
        </p>
            <p class="small-text-label-less">
               2) We do not rent, trade or share your personal information that we have collected with any other company for their marketing purposes without your consent. We acknowledge that you and your information is one of our most important assets.
            </p >
            <p class="small-text-label-less">
               3) We reserve the right to communicate your personal information to any third party that makes a legally-compliant request for its disclosure.
            </p>
    <br>
    <div class="align-left text-label">Policy Review: </div>
    <br>
    <p class="small-text-label">The website/application reserves the right to change or update this policy at any time. Such changes shall be effective immediately upon posting to the Site/Application.</p>
    
    <!--<div class="link-row" style="margin-top: 50px;position: fixed;bottom: 5px;">
      <a href="/"><span translate class="white-text-copyright">Home</span></a>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       <a href="/contact"><span translate class="white-text-copyright">Contact</span></a>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       <a href="/privacy-policy"><span translate class="white-text-copyright">Privacy-Policy</span></a>
       
     </div>-->
     <footer class="footer text-center">
      <div class="container">
        <div class="row pt-5">
          <div class="col-sm-12 col-12 pt-3 footer-list">
            <hr class="bg-light">
            <ul class="">
              <li><a translate href="/" class="text-white">Home</a></li>
              <li class="d-none d-sm-block"><a href="" class="text-white">|</a></li>
              <li><a translate href="/contact" class="text-white">Contact</a></li>
              <li class="d-none d-sm-block"><a href="" class="text-white">|</a></li>
              <li><a translate href="/privacy-policy" class="text-white">Privacy-Policy</a></li>
              </ul>
          </div>
      </div></div>
    <div class="footer-bottom">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 pt-4">
                <p translate class="text-white text-center pt-1">Copyright © 2022 Mazeed. All Rights Reserved.</p>
            </div>
        </div></div> 
    </div>
    </footer>
  </div>