import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  constructor(private translateService: TranslateService) {
     console.log(this.translateService) ;
     this.translateService.setDefaultLang('ar');
     this.translateService.use('ar');
   }
  ngOnInit(): void {
  }

}
