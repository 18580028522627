<div class="feedback-container">
    <P translate *ngIf="error != null" class="error-text">{{error | translate}}</P>
    <!--<P translate *ngIf="error != null" class="error-text">{{error | translate}}</P>-->
    <label translate class="text-label">Contact Us</label>
    <label translate class="small-text-label">Have any questions? We would love to hear from you</label>
    <br>
    <!--<input type="text" placeholder="Name*"></input>
    <input type="text" placeholder="Email*"></input>
    <input type="text" placeholder="Phone"></input>-->
    <br>
    <div>
        <input name="name" id="name" [(ngModel)]="name" class="input-text" type="text" placeholder = "*اسم"  required/>
    </div>
    <br>
    <div>
        <input name="email" id="email" [(ngModel)]="email" class="input-text" type="email" placeholder = "*البريد الإلكتروني" required/>
    </div>
    <br>
    <div>
        <input name="phone" id="phone" [(ngModel)]="phone" class="input-text" type="text" placeholder = "هاتف" />
    </div>
    <br>
    <div>
      <textarea translate class="message-text"  [(ngModel)]="messageVal" translate placeholder  = "رسالة" style = "padding-left: 11px;
      padding-top: 12px; font-family: Poppins-regular,sans-serif;" name="messageVal" id="messageVal"></textarea>
    </div>
    <br>
    <button translate class="send-button" (click)="onSubmit($event)">Send Message</button>
    <br>
    <button translate class="send-button-orange" (click)="goHome($event)">Home</button>
    <br>
    <progress-bar *ngIf="isSending" [progress]="100" [color]="'#488aff'">
    </progress-bar>
    <br>
    <P translate *ngIf="isSent" class="normal-text">{{message | translate}}</P>
    <!--<div class="link-row" style="margin-top: 50px;position: fixed;bottom: 5px;">
        <a href="/"><span translate class="white-text-copyright">Home</span></a>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         <a href="/contact"><span translate class="white-text-copyright">Contact</span></a>
         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         <a href="/privacy-policy"><span translate class="white-text-copyright">Privacy-Policy</span></a>
         
       </div>
      <br>
      &nbsp;
      <br>-->
      <footer class="footer">
        <div class="container">
          <div class="row pt-5">
            <div class="col-sm-12 col-12 pt-3 footer-list">
              <hr class="bg-light">
              <ul class="d-flex justify-content-center align-items-center">
                <li><a translate href="/" class="text-white">Home</a></li>
                <li class="d-none d-sm-block"><a href="" class="text-white">|</a></li>
                <li><a translate href="/contact" class="text-white">Contact</a></li>
                <li class="d-none d-sm-block"><a href="" class="text-white">|</a></li>
                <li><a translate href="/privacy-policy" class="text-white">Privacy-Policy</a></li>
                </ul>
            </div>
        </div></div>
      <div class="footer-bottom">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 pt-4">
                  <p translate class="text-white text-center pt-1">Copyright © 2022 Mazeed. All Rights Reserved.</p>
              </div>
          </div></div> 
      </div>
      </footer>

  </div>
  